/* Questionnaire List */
.questionnaire-list {
}
.questionnaire-list .panel {
  background: #f5f5f5;
  border: 1px solid #c4c4c4;
  margin: 0 0 15px;
}
.questionnaire-list .panel p {
  margin: 0;
}
.questionnaire-list .panel .panel-body {
  padding: 10px;
  word-break: break-all;
}
.questionnaire-list .panel .panel-foot {
  padding: 10px;
  border-top: 1px solid #c4c4c4;
  color: #4f4f4f;
}

.questionnaire-form {
  margin: -10px;
}
.questionnaire-form .sv_main {
  border-radius: 5px;
}
.questionnaire-form .sv_main .sv_header {
  border-radius: 5px;
  color: #000;
  background: #ffffff;
  display: none;
}
.questionnaire-form .sv_main .sv_header .sv_header__text {
  display: flex;
  display: flex;
  justify-content: space-between;
}
.questionnaire-form .sv_main .sv_header .sv_header__text h3 {
  font-size: 16px;
  font-weight: 600;
}
.questionnaire-form .sv_main .sv_header .sv_header__text h5 {
  margin: 0;
  padding: 0;
}
.questionnaire-form .sv_main .sv_custom_header {
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
}
.questionnaire-form .sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0;
  margin-bottom: 10px;
}
.questionnaire-form .sv_main .sv_p_root > .sv_row {
  border-bottom: 0;
  background-color: unset !important;
}
.questionnaire-form .sv_main .sv_container .sv_body .sv_nav {
  text-align: right;
}
.questionnaire-form .sv_preview_btn,
.questionnaire-form .sv_complete_btn,
.questionnaire-form .sv_edit_btn {
  background-color: var(--bottom_menu_active_color) !important;
  padding: 7px 10px !important;
  position: relative;
  display: inline-block;
  float: unset !important;
  border-radius: 5px !important;
}
.questionnaire-form .sv_main .sv_select_wrapper:before {
  background-color: var(--bottom_menu_active_color);
}
.questionnaire-form .sv_main .sv-title-actions__title {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.questionnaire-form .sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid var(--bottom_menu_active_color);
}
.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: var(--bottom_menu_active_color) !important;
  border-color: var(--bottom_menu_active_color) !important;
  color: white !important;
}
.sv_main .sv_body {
  border-top: 1px solid #bbbbbb;
}
.questionnaire-form .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  padding: 0 1px;
}
.questionnaire-form
  .sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_other
  input,
.questionnaire-form
  .sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_dropdown_control,
.questionnaire-form
  .sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]),
.sv_main .sv_container .sv_body .sv_p_root .sv_q select {
  border-radius: 4px;
  height: calc(2.5em + 1px);
  background: transparent;
  border: 1px solid var(--sidebar_boder_color);
  color: #fff;
}

.questionnaire-form
  .sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_select_wrapper:before {
  padding: 1.2em;
  border-radius: 0 5px 5px 0;
}
.questionnaire-form .sv_main .sv_q_other input:focus,
.questionnaire-form .sv_main .sv_q_text_root:focus,
.questionnaire-form .sv_main .sv_q_dropdown_control:focus,
.questionnaire-form
  .sv_main
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border: 1px solid #1c375c;
}
.questionnaire-form .sv_main .sv_container .sv_body {
  border-top: 0;
  background: var(--sidebar_bg_color);
  padding: 10px, 15px;
}
#sp_102 .sv_p_footer {
  padding: 0;
  margin-bottom: -50px;
}
.questionnaire-form .sv_main textarea {
  background: transparent;
  border: 1px solid var(--sidebar_boder_color);
  color: #fff;
}
.questionnaire-form textarea:focus {
  border-color: #7d7d7d !important;
}
.questionnaire-form
  .sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_select_wrapper {
  background: transparent;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  color: #fff;
}
.questionnaire-form input:focus {
  border-color: #7d7d7d !important;
}

.questionnaire-form .sv_main .sv_container .sv_body .sv_nav {
  padding: 0;
}
.questionnaire-form .sv_main .sv_p_footer {
  padding: 0;
  position: absolute;
}

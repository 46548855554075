.inner-form-group {


  .react-tel-input {
    .form-control {
      width: 100%;
      height: 40px;
      background-color: transparent;
      border: 1px solid var(--sidebar_boder_color);
    }
  }
  &.focus {
    outline: none;
    border:1px solid #7d7d7d;
    border-radius: 5px;
    .react-tel-input {
      .form-control {
        border: none;
      }
    }
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: 0 !important;
    &.open {
      .selected-flag {
        background-color: transparent;
      }
    }
  }
  .selected-flag:hover,
  .selected-flag:focus {
    background-color: transparent !important;
  }
  .country-list {
    margin: 0 0 10px -1px;
    background-color: transparent;
    overflow-y: auto;

    .country.highlight {
      background-color: var(--bottom_menu_hover_color);
    }
    .country {
      &:hover {
        background-color: var(--primary_button_fill_color) !important;
      }
      .dial-code {
        color: var(--bottom_menu_text_color);
      }
    }
  }
}

.react-tel-input
  .country-list
  .react-tel-input
  .country-list
  .country
  .dial-code {
  color: var(--bottom_menu_text_color);
}

.center-model {
  max-width: 750px !important;
  margin: auto;
  left: 0;
  right: 0;
  top: 0 !important;
  height: 60% !important;
  bottom: 0 !important;
  border-radius: 10px;
  border: 1px solid var(--sidebar_boder_color);
}
.call-setting-modal .inner-setting-model {
  display: flex;
  height: 100%;
}
.call-setting-modal .inner-setting-model .call-setting-tab {
  width: 30%;
  border-right: 1px solid var(--sidebar_boder_color);
}
.call-setting-modal .inner-setting-model .call-setting-tab .side-head {
  border-bottom: 0;
  padding: 15px;
  display: flex;
  align-items: center;
}
.call-setting-modal .inner-setting-model .call-setting-tab .setting-tab-list {
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li {
  margin-bottom: 5px;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li
  button {
  width: 100%;
  border-radius: 0 30px 30px 0;
  color: #fff;
  text-align: left;
  padding: 16px 15px;
  display: flex;
  align-items: center;
  transition: 0.4s ease all;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li
  button:hover {
  background: var(--sidebar_boder_color);
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li
  button
  i {
  width: 22px;
  text-align: center;
  margin-right: 15px;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li
  button
  .icon-call-audio {
  font-size: 23px;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .setting-tab-list
  ul
  li.active
  button {
  background: #3c4043;
}
.call-setting-modal .inner-setting-model .call-setting-content {
  width: 70%;
}
.call-setting-modal .inner-setting-model .call-setting-content .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  color: #fff;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-content
  .tab-close
  .close-modal {
  color: #fff;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-content
  .inner-setting-content {
  padding: 0 15px 15px;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-content
  .inner-setting-content
  .tab-content {
  display: none;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-content
  .inner-setting-content
  .tab-content.active {
  display: block;
}
.call-setting-modal.comman-modal .comman-modal-main .comman-modal-body {
  padding: 0;
}
.call-setting-modal.comman-modal .comman-modal-main .comman-modal-body {
  height: 100%;
}
.call-setting-modal.comman-modal .comman-modal-main .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
}
.call-setting-modal .inner-setting-model .my-prograss {
  max-width: 300px;
  width: 100%;
  height: 5px;
  margin: 15px 0;
  background-color: #404040;
  border-radius: 5px;
}
.call-setting-modal .inner-setting-model .my-prograss #myBar {
  height: 5px;
  background-color: var(--primary_button_fill_color);
  border-radius: 5px;
}
.call-setting-modal
  .inner-setting-model
  .call-setting-tab
  .side-head
  .close-modal {
  display: none;
}
.call-setting-modal .call-setting-content .check-microphone-step ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.call-setting-modal .call-setting-content .check-microphone-step ul li {
  background-color: #c4c4c4;
  width: 30px;
  height: 8px;
  border-radius: 20px;
  margin-right: 5px;
}
.call-setting-modal .call-setting-content .check-microphone-step ul li.active {
  background-color: #334aa6;
}

.call-setting-modal .acccess-camera-box {
  max-width: 200px;
  margin: 15px 0;
  padding: 15px;
  background-color: var(--bottom_menu_hover_color);
  border-radius: 10px;
  text-align: center;
}
.call-setting-modal .acccess-camera-box .icon-camera-off {
  color: #f00;
  margin-bottom: 10px;
}
.call-setting-modal .acccess-camera-box p {
  color: #fff;
}

.call-setting-modal .acccess-camera-box {
  max-width: 230px;
  margin: 15px 0;
  padding: 7px;
  background-color: var(--bottom_menu_hover_color);
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .call-setting-modal
    .inner-setting-model
    .call-setting-tab
    .side-head
    .close-modal {
    display: inline-block;
  }
  .call-setting-modal .inner-setting-model .call-setting-content .tab-close {
    display: none;
  }
  .call-setting-modal
    .inner-setting-model
    .call-setting-content
    .inner-setting-content {
    padding: 15px;
  }
  .center-model {
    height: 100% !important;
    border-radius: 0;
  }
  .call-setting-modal .inner-setting-model {
    flex-flow: column;
  }
  .call-setting-modal .inner-setting-model .call-setting-tab {
    width: 100%;
    height: auto;
  }
  .call-setting-modal .inner-setting-model .call-setting-content {
    width: 100%;
  }
  .call-setting-modal .inner-setting-model .call-setting-tab .setting-tab-list {
    padding: 0 15px;
  }
  .call-setting-modal
    .inner-setting-model
    .call-setting-tab
    .setting-tab-list
    ul {
    display: flex;
    flex-flow: wrap;
  }
  .call-setting-modal
    .inner-setting-model
    .call-setting-tab
    .setting-tab-list
    ul
    li {
    margin-right: 15px;
  }
  .call-setting-modal
    .inner-setting-model
    .call-setting-tab
    .setting-tab-list
    ul
    li
    button {
    height: 55px;
    border-radius: 30px;
    padding: 16px 30px;
  }
}

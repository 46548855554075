/* Gallery Zoom View images css */
.download_images_sec {
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 15;
}
.download_images_sec .image_content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 30px 0;
}
.download_images_sec .download_img_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  color: var(--bottom_menu_text_color);
}
.download_images_sec .download_img_header .image_count p {
  padding: 5px 10px;
  margin: 0;
  font-size: 12px;
}
.download_images_sec .download_img_header .download_img_btn {
  z-index: 10;
}
.download_images_sec .download_img_header .download_img_btn .download_img_icon {
  background-color: var(--bottom_menu_bg_color);
}
.download_images_sec .download_img_header .download_img_btn button {
  color: var(--bottom_menu_text_color);
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;

  &:hover {
    background-color: var(--sidebar_boder_color);
  }
}
.download_images_sec .download_img_header .download_img_btn:focus {
  border: none;
  outline: none;
}
.download_images_sec .left_arrow,
.download_images_sec .right_arrow {
  background-color: var(--bottom_menu_bg_color);
  position: absolute;
  left: 0;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.download_images_sec .left_arrow {
  .arrowDisabled {
    cursor: not-allowed;

    &:hover {
      background-color: var(--bottom_menu_bg_color);
    }
  }
}

.download_images_sec .right_arrow {
  .arrowDisabled {
    cursor: not-allowed;

    &:hover {
      background-color: var(--bottom_menu_bg_color);
    }
  }
}

.download_images_sec .left_arrow button,
.download_images_sec .right_arrow button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  color: var(--bottom_menu_text_color);
  width: 35px;
  height: 35px;

  &:hover {
    background-color: var(--sidebar_boder_color);
  }
}
.download_images_sec .right_arrow {
  right: 0;
  left: unset;
}

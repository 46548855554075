/* Markup View */

.markup-preparing {
  display: flex;
  color: var(--page_body_text_color);
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.markup-preparing .box h4 {
  font-weight: normal;
  font-size: 20px;
  margin: 15px 0;
}
.markup-preparing .box .img {
  background: var(--bottom_menu_bg_color);
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 45px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.markup-view {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  background: rgb(0 0 0 / 100%);
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 11;
}
.markup-view .image-view img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 30px 0;
}
.markup-view .markup-menu {
  position: absolute;
  left: 20px;
  z-index: 11;
}
.markup-view .markup-menu ul {
  margin: 0;
  padding: 10px 0;
  background: var(--bottom_menu_bg_color);
  border-radius: 60px;
  list-style: none;
}
.markup-view .markup-menu ul li {
  text-align: center;
  position: relative;
}
.markup-view .markup-menu ul li.disable > button {
  opacity: 0.4;
}
.markup-view .markup-menu ul li.active > button {
  color: var(--bottom_menu_active_color);
}
.markup-view .markup-menu ul li button {
  color: #fff;
  font-size: 22px;
  padding: 10px 20px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  width: 100%;
}
.markup-view .markup-menu ul li.clear button {
  border-color: var(--sidebar_boder_color);
}
.markup-view .markup-menu ul li.undo-p button,
.markup-view .markup-menu ul li.undo-n button {
  font-size: 19px;
}
.markup-view .markup-menu ul li.undo-n button {
  border-color: var(--sidebar_boder_color);
}
.markup-view .markup-menu ul li.edit button {
  border-color: var(--sidebar_boder_color);
}
.markup-view .markup-menu ul li.draw button {
}
.markup-view .markup-menu ul li.cancel button {
  color: #ff0000;
  font-size: 20px;
}
.markup-view .markup-menu ul li .dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  background: var(--bottom_menu_bg_color);
  padding: 10px 10px 5px;
  margin: 0 0 0 5px;
  width: 100%;
}
.markup-view .markup-menu ul li .dropdown-menu .close {
  font-size: 12px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.markup-view .markup-menu ul li .dropdown-menu ul {
  text-align: left;
  padding: 10px 0 0;
  clear: both;
  text-align: center;
}
.markup-view .markup-menu ul li .dropdown-menu ul li {
  display: inline-block;
}

.markup-view .radio-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.markup-view .radio-btn input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.markup-view .radio-btn span {
  display: block;
  width: 20px;
  height: 20px;
  background: #b2b2b2;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.markup-view .radio-btn input:checked ~ span {
  border: 1px solid #fff;
}

.markup-view .mobile-button {
  display: none;
  position: absolute;
  top: 0;
  justify-content: space-between;
  left: 0;
  right: 0;
  padding: 10px 5px;
  z-index: 10;
}
.markup-view .mobile-button .white-btn {
  padding: 8px 15px;
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin: 0 5px;
}

/* Markup View END*/

.markup-view .markup-menu .menu-icons-btn {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.markup-view .markup-menu .menu-icons-btn li {
  margin: 0 0 10px 0;
}
.markup-view .markup-menu .menu-icons-btn li button {
  margin: 0;
  padding: 0 !important;
  font-size: 18px !important;
}
.markup-view .markup-menu .menu-icons-btn li.disable button {
  opacity: 0.4;
}
.markup-view .markup-menu .menu-icons-btn li.active button {
  color: var(--bottom_menu_active_color);
}

/* Markup Close Modal  */
.markup-message {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
}
.markup-message .right-message {
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  top: 15px;
  right: 0;
  background: #000;
}
.markup-message .box {
  background: var(--page_background_color);
  color: var(--page_body_text_color);
  max-width: 400px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 5px;
}
.markup-message .box .title {
  margin: 0 0 15px;
}
.markup-message .box .title h4 {
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.markup-message .box .title .close {
  padding: 0;
}
.markup-message .box .markup-action {
  margin: 5px 0 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
}
.markup-message .box .markup-action button {
  margin: 0 5px;
  font-size: 12px;
  padding: 6px 10px;
}
.icon-text-selection:before {
  content: "\e991";
}

/* Markup Close Modal  */

@media (max-width: 1024px) {
  /* Markup View */
  .markup-view .markup-menu {
    justify-content: center;
    display: flex;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .markup-view .markup-menu ul {
    padding: 0 10px;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
  }
  .markup-view .markup-menu ul li {
    display: flex;
  }
  .markup-view .markup-menu ul li .dropdown-menu {
    top: unset;
    left: unset;
    bottom: 100%;
    margin: 0 0 5px 0px;
    width: 70px;
  }
  .markup-view .markup-menu ul li button {
    padding: 10px 5px;
    font-size: 19px;
    border-bottom: none;
  }
  .markup-view .markup-menu ul li.undo-p button,
  .markup-view .markup-menu ul li.undo-n button {
    font-size: 16px;
  }
  .markup-view .image-view img {
    padding: 10px 0 50px;
  }
  .markup-view .mobile-button {
    display: flex;
  }
  .markup-view .markup-menu ul li.clear,
  .markup-view .markup-menu ul li.download,
  .markup-view .markup-menu ul li.cancel {
    display: none;
  }
  /* Markup View End */
}

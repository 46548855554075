.multiple-po-list .radio-btn {
  display: block;
  margin: 0 0 10px 0;
}
.multiple-po-list .radio-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.multiple-po-list .radio-btn input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.multiple-po-list .radio-btn span {
  padding-left: 30px;
  display: block;
  position: relative;
}
.multiple-po-list .radio-btn span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--sidebar_body_text_color);
  border-radius: 50%;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.multiple-po-list .radio-btn input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--sidebar_body_text_color);
}

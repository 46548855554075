.measurement-appraisal {
}
.measurement-appraisal {
  position: fixed;
  left: -350px;
  top: 0px;
  right: 0px;
  bottom: 0;
  background: var(--bottom_menu_bg_color);
  z-index: 10;
  width: 320px;
  margin: 30px 0 0 30px;
  border-radius: 10px;
  border: 1px solid var(--sidebar_boder_color);
  height: calc(100% - 60px);
  transition: 0.4s ease all;
}
.measurement-appraisal .appraisal-modal {
  height: 100%;
  .appraisal-menu {
    margin: -10px;
    height: 100%;
    .instructions-button-list {
      li {
        margin: 0;
        border-bottom: 0;
      }
    }
  }
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  height: calc(100% - 27px);
  overflow-y: auto;
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li {
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button {
  color: var(--bottom_menu_text_color);
  display: flex;
  width: 100%;
  padding: 10px 10px;
  align-items: center;
  font-size: 18px;
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button:hover {
  background: var(--bottom_menu_hover_color);
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button:active {
  background-color: var(--sidebar_boder_color);
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button i {
  width: 32px;
  text-align: left;
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button .text {
  font-size: 14px;
  width: calc(100% - 24px);
  text-align: left;
  text-overflow: ellipsis;
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li > button.active {
  color: var(--bottom_menu_active_color);
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li .view-form-group {
  padding: 10px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li
  .view-form-group
  .form-group
  label {
  color: var(--bottom_menu_text_color);
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li
  button
  .icon-close-image {
  color: #ff0000;
}

.measurement-appraisal .side-head {
  color: var(--sidebar_body_text_color);
}

.measurement-appraisal .side-toggle {
  background: var(--primary_button_fill_color);
  color: var(--primary_button_text_color);
  position: absolute;
  right: -30px;
  top: 120px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 22px;
  padding: 0;
  justify-content: center;
  border-radius: 0 5px 5px 0;
}
.measurement-appraisal.open {
  left: 0;
}
.measurement-appraisal .appraisal-modal .appraisal-menu ul li.measurement-type {
  padding: 10px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  > label {
  color: #fff;
  display: inline-block;
  margin-bottom: 7px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .form-group {
  margin: 0;
  display: flex;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn {
  color: #fff;
  margin-right: 15px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn
  label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn
  input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn
  span {
  padding-left: 30px;
  display: block;
  position: relative;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn
  span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--sidebar_body_text_color);
  border-radius: 50%;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.measurement-type
  .radio-btn
  input:checked
  ~ span:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  right: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--sidebar_body_text_color);
}

div.appraisal-menu ul li button.disable {
  opacity: 0.4;
}

.measurement-appraisal .appraisal-modal .appraisal-menu ul li .font14 {
  font-size: 14px;
}

@media (max-width: 767px) {
  .measurement-appraisal,
  .start-appraisal-tab-modal {
    max-width: 250px;
    left: -250px;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
}

@media (max-width: 568px) {
  .measurement-appraisal,
  .start-appraisal-tab-modal {
    max-width: 250px;
    left: -250px;
    margin: 0;
    height: 100%;
    border-radius: 0;
  }
}

/* Yes No Toggle */
.appraisal-menu-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.appraisal-menu-button .menu-button {
  display: flex;
}
.toggle .text {
  margin-left: 10px;
}
.toggle .toggle-box {
  display: inline-block;
  padding: 2px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
}
.toggle .toggle-box .radio-button {
  cursor: pointer;
  display: inline-block;
  padding: 0 2px;
  position: relative;
}
.toggle .toggle-box input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.toggle .toggle-box span {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  padding: 4px 6px;
  font-size: 12px;
  color: #000;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.toggle .toggle-box input:checked ~ span {
  background: #334aa6;
  color: #fff;
}
.toggle .toggle-box .radio-button:last-child input:checked ~ span {
  background: #334aa6;
  color: #fff;
}

.icon-Fencing:before {
  content: "\e98f";
}
.icon-Live-Floor-Plan:before {
  content: "\e990";
}

.measurement-appraisal .appraisal-modal .appraisal-menu ul li.form-select {
  padding: 10px;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.form-select
  label {
  color: #fff;
  margin-bottom: 5px;
  display: block;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.form-select
  select {
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  background: transparent;
  color: #fff;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.form-select
  select
  option {
  color: #000;
}
.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.form-select
  select
  option:selected {
  color: #000;
}

.custom-tab.orange-tab .tab-list li.active button {
  color: #ffa500;
}
.custom-tab.orange-tab .tab-list li.active button:after {
  content: "";
  border-bottom: 1px solid #ffa500;
}

.custom-tab.orange-tab .tab-list li button {
  color: #ffa500;
}

.measurement-appraisal
  .appraisal-modal
  .appraisal-menu
  ul
  li.form-select
  select
  option {
  color: #000000;
}

.view-form-group .opensketch-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.error-popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 100;
}
.error-popup.open .error-info {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.error-popup .error-info {
  opacity: 0;
  position: relative;
  border-radius: 10px;
  padding: 20px;

  background: var(--page_background_color);
  color: var(--page_body_text_color);

  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.error-popup .error-info .content {
  margin: 10px 0 20px;
}
.error-popup .error-info .content .close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--page_body_text_color);
}
.error-popup.open .error-info .bottom-btn button {
  margin: 0 5px;
}
.error-popup .error-info .content .link-btn {
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  color: #334aa6;
  text-decoration: none;
}
.error-popup .error-info.text-left {
  text-align: left;
}

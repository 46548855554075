/* Zoom Level */
.zoom_outer {
  display: inline-block;
  position: fixed;
  bottom: 18%;
  right: 30px;
  &.open {
    right: 220px;
  }
  span {
    color: var(--page_body_text_color);
    &.zoom-level {
      transform: rotate(90deg);
      position: absolute;
      white-space: nowrap;
      bottom: 40px;
      left: -20px;
    }
  }
  .zoom_main {
    background-color: #fff;
    width: 10px;
    height: 150px;
    border-radius: 50px;
    margin: 10px auto;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    .level {
      position: relative;
      label {
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        &.not-allowed {
          cursor: not-allowed;
        }
      }

      input {
        position: absolute;
        left: -10px;
        top: 0;
        opacity: 0;
        visibility: hidden;
        padding: 0;
        margin: 0;
        right: 0;
        width: 20px;
        height: 20px;

        &:checked {
          background-color: red !important;
          ~ span {
            background: var(--bottom_menu_active_color);
            color: var(--bottom_menu_active_color);
          }
        }
      }
      span {
        display: block;
        border-radius: 50%;
        line-height: 43px;
        text-align: left;
        margin: 0 auto;
        color: var(--primary_button_text_color);
        width: 35px;
        height: 5px;
        background-color: #fff;
        border-radius: 2px;
        transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -ms-transition: all 0.1s linear;
        -o-transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        position: absolute;
        right: -10px;

        // &:hover {
        //   background-color: var(--primary_button_fill_color);
        //   color: var(--primary_button_fill_color);
        // }
      }
    }
  }
}

@media (max-width: 767px) {
  .zoom_outer span {
    display: none;
  }
  .zoom_outer .zoom_main .level span:hover {
    background: none;
  }
}

@media only screen and (max-width: 380px) {
  .zoom_outer {
    right: 5px;
  }
}

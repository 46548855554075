/* Markup preparing  */

.markup-preparing {
  display: flex;
  color: #fff;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.markup-preparing .box h4 {
  font-weight: normal;
  font-size: 20px;
  margin: 15px 0;
}
.markup-preparing .box .img {
  background: #2c2c2c;
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  font-size: 45px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@media only screen and (max-width: 380px) {
  .markup-preparing .box .img {
    width: 70px;
    height: 70px;
    font-size: 30px;
  }
  .markup-preparing .box h4 {
    font-size: 14px;
  }
}
/* Markup preparing  */

section.callend-pagenot-found {
  background: var(--page_background_color);
  color: var(--page_body_text_color);
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
section.callend-pagenot-found .box-center .content h2 {
  font-weight: normal;
  margin: 20px 0 5px;
  font-size: 30px;
}
section.callend-pagenot-found .box-center .reconnect {
  margin: 20px 0 0;
}
section.callend-pagenot-found .box-center .reconnect button {
  padding: 10px 25px;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 7px;
}

section.callend-pagenot-found .box-center .content p {
  font-size: 18px;
}
section.callend-pagenot-found .box-center .notfound-img {
  font-size: 6vw;
}
section.callend-pagenot-found .box-center .content .company-logo {
  border-radius: 10px;
  display: inline-block;
  line-height: 0;
}
section.callend-pagenot-found .box-center .content .company-logo img {
  max-height: 125px;
}

@media (max-width: 767px) {
  section.callend-pagenot-found .box-center .content h2 {
    font-size: 24px;
  }
  section.callend-pagenot-found .box-center .notfound-img {
    font-size: 10vw;
  }
}

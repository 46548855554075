/* Bookmark View */
.bookmark-view {
}
.bookmark-view .search-view {
  position: relative;
}
.bookmark-view .search-view button.search {
  position: absolute;
  top: 11px;
  right: 6px;
  font-size: 14px;
  color: var(--sidebar_body_text_color);
}
.bookmark-view .search-view input[type="search"] {
  border-radius: 0;
}
.bookmark-view .search-view button.create-book {
  background: var(--primary_button_fill_color);
  padding: 10px 20px;
  display: block;
  width: 100%;
  color: var(--primary_button_text_color);
  margin: 0 0 0;
  position: absolute;
  top: 100%;
}
.bookmark-view .no-bookmark {
  height: calc(100vh - 132px);
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--sidebar_body_text_color);
}

.bookmark-view .bookmark-list {
  margin: 10px -10px;
  overflow-y: auto;
  height: calc(100vh - 140px);
}
.bookmark-view .bookmark-list ul {
  padding: 0;
  margin: 0;
}
.bookmark-view .bookmark-list ul li {
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.bookmark-view .bookmark-list ul li button {
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: var(--sidebar_body_text_color);
}
.bookmark-view .bookmark-list ul li button:hover {
  background: var(--bottom_menu_hover_color);
}

/* Chat Modal */
.chat-modal {
}
.chat-modal .comman-modal-main {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.comman-modal.chat-modal .comman-modal-main .comman-modal-body {
  padding: 0px;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.chat-view {
  z-index: 10;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}
.chat-view .chat-list {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 10px 10px 0;
}
.chat-view .chat-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
}
.chat-view .chat-list ul li {
  clear: both;
  word-wrap: break-word;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 0 0 10px;

  &:last-child {
    padding: 0;
  }
}

.chat-view .chat-list ul li .chat-title {
  margin: 0 0 5px;
}
.chat-view .chat-list ul li .chat-title span {
  opacity: 0.6;
  font-size: 13px;
}
.chat-view .chat-list ul li .details {
  opacity: 0.6;
  font-size: 14px;
}
.chat-view .chat-list ul li .details p {
  margin: 0;
}
.chat-view .send-chat {
  padding: 0px 10px 10px;
}
.chat-view .send-chat .chat-input {
  position: relative;
}
.chat-view .send-chat .chat-input .form-control {
  border-color: var(--sidebar_boder_color);
  border-radius: 0;
}
.chat-view .send-chat .chat-input .send {
  position: absolute;
  right: 3px;
  top: 2px;
  border-radius: 5px;
  padding: 10px 12px;
  color: #fff;
  font-size: 14px;
}
.chat-view .send-chat .chat-input .disabled {
  opacity: 0.6;
  color: #fff;
  pointer-events: auto;
  cursor: no-drop;
}
.chat-view .send-chat .join-call {
  padding: 10px 0;
  display: none;
}
.chat-view .send-chat .join-call select {
  outline: none;
  background: transparent;
  color: #fff;
  margin-left: 10px;
  height: 40px;
  border-radius: 5px;
  border-color: var(--sidebar_boder_color);
  padding: 0 10px;
}
.chat-view .send-chat .join-call select option {
  color: #000;
}

.chat-view .send-chat .chat-input .chat-area {
  display: grid;
  word-break: break-all;
}
.chat-view .send-chat .chat-input .chat-area::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.chat-view .send-chat .chat-input .chat-area > textarea {
  border-radius: 30px;
  resize: none;
  overflow: hidden;
}
.chat-view .send-chat .chat-input .chat-area > textarea,
.chat-view .send-chat .chat-input .chat-area::after {
  color: #fff;
  padding: 8px 43px 7px 10px;
  background: var(--page_background_color);
  outline: none;
  border: 0;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

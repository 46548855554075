@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import "./variables.scss";
*,
::after,
::before {
  box-sizing: border-box;
}

:root {
  --page_background_color: #222222;
  --page_body_text_color: #ffffff;

  --sidebar_boder_color: rgba(255, 255, 255, 0.15);
  --sidebar_bg_color: #191919;
  --sidebar_body_text_color: #fff;

  --primary_button_fill_color: #334aa6;
  --primary_button_text_color: #ffffff;
  --primary_button_hover_color: #172a75;
  --primary_button_hover_text_color: #ffffff;

  --secondary_button_fill_color: #e1e1e1;
  --secondary_button_text_color: #000000;
  --secondary_button_hover_color: #c1c1c1;
  --secondary_button_Hover_text_color: #000000;

  --bottom_menu_bg_color: #191919;
  --bottom_menu_text_color: #ffffff;
  --bottom_menu_hover_color: #222222;
  --bottom_menu_active_color: #4ec53b;
  --scrollbar_bg_color: #000;

  --green_button_bg: #4ec53b;
  --green_button_bg_hover: #2d9b1b;
  --green_button_bg_active: rgba(78, 197, 59, 0.47);

  --header_footer_bg: #ffffff;
  --header_text_color: #1c375c;
  --body_background_color: #1c375c;
  --body_text_color: #000000;
  --button_bg_color: #1c375c;
  --button_text_color: #ffffff;

  --bottom_menu_default_bg: #3c4043;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: $app-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.42;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}
button {
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
button:focus {
  box-shadow: none;
  outline: none;
}
p {
  margin: 0 0 10px;
  font-weight: 400;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

textarea.form-control {
  padding: 10px 15px;
  height: auto;
}
.form-group {
  margin: 0 0 15px;
}
.form-group label {
  font-weight: 400;
  margin: 0 0 5px;
  display: inline-block;
}
.form-control {
  font-family: $app-font;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-color: #888888;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  background-clip: padding-box;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  outline: none;
  border-color: $control-border-color;
}
.form-control:disabled {
  background: var(--bottom_menu_hover_color);
}

select.form-control {
  color: var(--sidebar_body_text_color);
}
select.form-control option {
  color: #000;
}

/* Comman Button */
.blue-btn {
  background: var(--primary_button_fill_color);
  padding: 10px 20px;
  border-radius: 5px;
  color: var(--primary_button_text_color);
}
.blue-btn:hover {
  background: var(--primary_button_hover_color);
  color: var(--primary_button_hover_text_color);
}
.blue-btn i {
  margin-right: 7px;
}

.gray-btn {
  background: #e1e1e1;
  padding: 10px 20px;
  border-radius: 5px;
  color: #444444;
}

.gray-btn:hover {
  background: var(--secondary_button_hover_color);
  color: var(--secondary_button_Hover_text_color);
}

.outline-btn {
  border: 1px solid #334aa6;
  padding: 10px 20px;
  border-radius: 5px;
  color: #334aa6;
}

.green-btn {
  padding: 10px 20px;
  background: var(--green_button_bg);
  border-radius: 5px;
  color: #fff;

  &:hover {
    background-color: var(--green_button_bg_hover);
  }

  &:active {
    background-color: var(--green_button_bg_active);
  }
}

.red-btn {
  background: #fe1743;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
}
.red-btn:hover {
  background: #d20028;
}

/* Scrollbar */
.scroll-bar-style::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}
.scroll-bar-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--scrollbar_bg_color);
  border-radius: 8px;
}
.scroll-bar-style::-webkit-scrollbar-thumb {
  background-color: var(
    --primary_button_fill_color
  ); /* outline: 1px solid #B1BFCB; */
  border-radius: 8px;
}

/* Css Tab Design Without JS open tab */
.custom-tabs .tabbed [type="radio"] {
  display: none;
}
.custom-tabs .tabbed .tabs {
  margin: 0px -15px 10px;
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
}
.custom-tabs .tabbed .tabs .tab {
  width: 50%;
}
.custom-tabs .tabbed .tabs .tab > label {
  display: block;
  padding: 7px 0px;
  color: #707070;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}
.custom-tabs .tabbed .tabs .tab > label i {
  margin-right: 10px;
}
.custom-tabs .tabbed .tabs .tab:hover label {
  border-top-color: #333;
  color: #333;
}
.custom-tabs .tabbed .tab-content {
  display: none;
  color: #777;
}
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(1):checked
  ~ .tabs
  .tab:nth-of-type(1)
  label,
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(2):checked
  ~ .tabs
  .tab:nth-of-type(2)
  label {
  position: relative;
  color: #334aa6;
}
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(1):checked
  ~ .tabs
  .tab:nth-of-type(1)
  label:before,
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(2):checked
  ~ .tabs
  .tab:nth-of-type(2)
  label:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  background: #334aa6;
  height: 2px;
}
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(1):checked
  ~ .tab-content:nth-of-type(1),
.custom-tabs
  .tabbed
  .tab-input:nth-of-type(2):checked
  ~ .tab-content:nth-of-type(2) {
  display: block;
}

/* SAVE IMAGES VIEW */
.side-head {
  border-bottom: 1px solid var(--sidebar_boder_color);
  padding: 10px 10px;
  position: relative;
  font-weight: 400;
  font-size: 16px;

  .head-invite-participent {
    color: #fff;
    text-decoration: none;
  }

  .side-head-buttons {
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;

    button {
      margin-left: 5px;
    }

    .add-new-note {
      color: #fff;
      text-decoration: none;
    }

    .close-modal {
      position: relative;
      top: unset;
      right: unset;
    }
  }
}

.modal-button-effect {
  color: #fff;
  background-color: var(--page_background_color);
  padding: 5px 7px;
  border-radius: 4px;
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--sidebar_boder_color);
  }

  &:active {
    background-color: var(--bottom_menu_hover_color);
  }
}

.side-head .icon {
  margin-right: 10px;
  position: relative;
  display: inline-block;
}
.side-head .icon span.count {
  position: absolute;
  top: -9px;
  font-size: 10px;
  font-weight: 500;
  left: 12px;
}
.side-head .close-modal {
  position: absolute;
  right: 5px;
  top: 13px;
  color: #fff;
  text-decoration: none;
}

.thumb-container {
  position: fixed;
  z-index: 10;
  top: 0px;
  right: -200px;
  width: 200px;
  height: 100%;
  -webkit-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -moz-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -o-transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  background-color: var(--sidebar_bg_color);
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.thumb-container.open {
  right: 0;
}
.thumb-container .side-toggle {
  position: absolute;
  left: -40px;
  top: 140px;
  background: var(--primary_button_fill_color);
  padding: 2px 0;
  width: 40px;
  color: var(--primary_button_text_color);
  border-radius: 5px 0 0 5px;
}
.thumb-container .side-toggle span {
  background: var(--primary_button_fill_color);
  filter: brightness(1.3);
  width: 22px;
  height: 22px;
  display: inline-block;
  line-height: 23px;
  border-radius: 50%;
  font-size: 13px;
  margin-left: 2px;
}

.thumb-container .thumb-image-div {
  padding: 10px;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.thumb-container .thumb-image-div:hover .thumb_bottom_postion .viewbtn button {
  opacity: 1;
}
.thumb-container
  .thumb-image-div:hover
  .thumb-image-info
  .thumb-image-delete
  button {
  opacity: 1;
}
.thumb-container .thumb-container-inner {
  color: var(--sidebar_body_text_color);
}
.thumb-container .thumb-container-inner .no-image-found {
  padding: 30px 10px;
  text-align: center;
}

.thumb-container .thumb-container-inner .thumb-body {
  height: calc(100vh - 42px);
  overflow-y: auto;
  color: #fff;
  padding-bottom: 30px;
}
.thumb-container .thumb-image-div .thumb_bottom_postion {
  position: relative;
}
.thumb-container .thumb-image-div .thumb_bottom_postion .viewbtn button {
  border-radius: 20px;
  padding: 3px 15px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  opacity: 0;
  font-size: 14px;
  text-align: center;
}
.thumb-container .thumb-image-div .thumb_bottom_postion .thumb-image img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.thumb-container .thumb-image-div .thumb-image {
  text-align: center;
}
.thumb-container .thumb-image-div .thumb-image-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thumb-container .thumb-image-div .thumb-image-info .caption {
  font-size: 14px;
}
.thumb-container .thumb-image-div .thumb-image-info .caption i {
  margin-right: 7px;
}
.thumb-container .thumb-image-div .thumb-image-info .thumb-image-delete button {
  color: #fff;
  opacity: 0;
  padding: 0;
  font-size: 16px;
}

/* COMMAN MODAL */

.comman-modal {
  color: var(--sidebar_body_text_color);
  position: fixed;
  z-index: 11;
  top: 0px;
  width: 100%;
  max-width: 320px;
  height: 100%;
  -webkit-transition: left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -moz-transition: left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -o-transition: left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transition: left 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  background: var(--sidebar_bg_color);
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.back-layer::after {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: -1;
}
.comman-modal.right-side {
  right: -320px;
}
.comman-modal.right-side.open {
  right: 0;
}
.comman-modal.left-side {
  left: -350px;
  right: auto;
  height: calc(100% - 60px);
  margin: 30px 0 0 30px;
  border-radius: 10px;
  border: 1px solid var(--sidebar_boder_color);
  transition: 0.4s ease all;
}

.side-toggle {
  background: var(--primary_button_fill_color);
  color: var(--primary_button_text_color);
  position: absolute;
  right: -30px;
  top: 50px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 22px;
  padding: 0;
  justify-content: center;
  border-radius: 0 5px 5px 0;
}

.comman-modal.left-side.open {
  opacity: 1;
  left: 0;
}
.comman-modal .comman-modal-main {
  height: 100%;
}
.comman-modal .comman-modal-main .comman-modal-body {
  padding: 10px;
  overflow-y: auto;
  height: calc(100% - 42px);
  overflow-x: hidden;
}
.comman-modal .comman-modal-main .comman-modal-body .form-group label {
  color: var(--page_body_text_color);
}
.comman-modal .modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #c4c4c4;
}
.comman-modal .form-control {
  color: var(--sidebar_body_text_color);
}
/* COMMAN MODAL END*/

.text-danger {
  color: #dc3545 !important;
}

.form-error {
  font-size: 14px;
  color: #ff0033;
  margin-top: 5px;
}

.border-none {
  border: none;
  border-bottom: 0 !important;
}

.Toastify__toast-container {
  width: auto;
  .Toastify__toast {
    padding: 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    max-width: 440px;
    box-shadow: 0px 0px 13px 0px #404040;
    min-height: 1px;
    color: #fff;
    width: fit-content;
    margin: 0 auto 1rem;
    &.success {
      background: rgb(2, 158, 0);
    }
    &.error {
      background: rgb(255, 0, 0);
    }
    &.upload-img {
      background: rgb(0, 0, 0);
    }
    &.warning {
      background: rgb(243, 168, 0);
    }
    .Toastify__toast-body {
      padding: 0;
      display: flex;
      align-items: center;
    }
    .cross-btn {
      color: #fff;
    }
  }
  &.Toastify__toast-container--top-center {
    @media (max-width: 480px) {
      top: 1em !important;
      left: 50% !important;
      -webkit-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
  }
}

/* Responsive CSS */

@media (max-width: 1199px) {
  .thumb-container .side-toggle {
    top: 160px;
  }
}

@media (max-width: 568px) {
  /* Comman Modal CSS*/
  .comman-modal {
    max-width: 100%;
  }
  .comman-modal.left-side {
    left: -100%;
  }
  /* Comman Modal CSS END*/
}

@media (max-width: 767px) {
  .comman-modal.left-side {
    height: 100%;
    margin: 0;
    border-radius: 0;
    border: 0;
  }
}

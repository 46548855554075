/* Participants Screen */

section.videocall-wapper
  .participants-main
  .participants-view
  .name
  .street-view
  > button {
  color: var(--bottom_menu_text_color);
}
section.videocall-wapper
  .participants-main
  .participants-view
  .name
  .street-view
  .street-drop
  button {
  color: var(--bottom_menu_text_color);
}

section.videocall-wapper .participants-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100% + 1px);
}
section.videocall-wapper .participants-main .participants-view {
  position: relative;
  border-right: 1px solid rgba(255, 255, 255, 0.13);
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
section.videocall-wapper .participants-main .participants-view .video-img {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;

  .generalize-text {
    width: 75%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #fff;
    background-color: var(--bottom_menu_default_bg);
    border-radius: 10px;

    .common-text {
      text-align: center;
    }
  }
}

section.videocall-wapper
  .participants-main
  .participants-view.full-screen
  .video-img {
  &.captured-stream-active {
    .live-stream {
      width: fit-content;
      height: calc(100% - 280px);
      video {
        height: 100%;
        position: relative;
      }
    }
    .captured-stream {
      height: calc(100% - 280px);
      margin-left: 20px;
    }
  }
}

section.videocall-wapper .participants-main .participants-view .video-img img {
  display: table;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}
section.videocall-wapper .participants-main .participants-view .no-video {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
}
section.videocall-wapper .participants-main .participants-view .no-video img {
  display: table;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}
section.videocall-wapper .participants-main .participants-view .name {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--page_body_text_color);
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 0px 0px 5px 5px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  z-index: 15;

  .user-name {
    margin-left: 10px;
    margin-right: 5px;
  }

  .location-name {
    display: inline-flex;
    .user-name {
      margin-left: 10px;
      margin-right: 5px;
      display: flex;

      .comma {
        display: none;
      }
    }
  }
}

section.videocall-wapper
  .participants-main
  .participants-view
  .pin-video
  button {
  font-size: 14px;
  position: absolute;
  left: 15px;
  top: 50px;

  background: rgba(255, 255, 255, 1);
  color: var(--secondary_button_text_color);

  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 42px;
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 15;
}
section.videocall-wapper
  .participants-main
  .participants-view:hover
  .pin-video
  button {
  opacity: 1;
}
section.videocall-wapper .participants-main .participants-view .name .location {
  display: none;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .name
  .street-view {
  display: inline-block;
  display: none;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .name
  .street-view
  button
  i {
  margin: 0;
  text-align: center;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .name
  .street-view
  .street-drop {
  position: absolute;
  top: 102%;
  background: var(--bottom_menu_bg_color);
  right: 0;
  border-radius: 5px;
  padding: 6px 10px;
}
section.videocall-wapper
  .participants-main
  .participants-view.full-screen
  .location,
section.videocall-wapper
  .participants-main
  .participants-view.full-screen
  .street-view {
  display: inline-block;
}
section.videocall-wapper .participants-main .participants-view.full-screen {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  background: var(--page_background_color);
  z-index: 18;

  .name {
    .location-name {
      .user-name {
        .comma {
          display: block;
        }
      }
    }
  }
}
section.videocall-wapper
  .participants-main.video-participants1
  .participants-view {
  width: 100%;
  height: 100%;
}
section.videocall-wapper
  .participants-main.video-participants2
  .participants-view {
  width: 50%;
  height: 100%;
}
section.videocall-wapper .participants-main.video-participants3 {
  flex-direction: column;
  flex-wrap: wrap;
}
section.videocall-wapper
  .participants-main.video-participants3
  .participants-view {
  width: 50%;
  height: 50%;
}
section.videocall-wapper
  .participants-main.video-participants3
  .participants-view:nth-child(3) {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
section.videocall-wapper .participants-main.video-participants4 {
  flex-direction: column;
  flex-wrap: wrap;
}
section.videocall-wapper
  .participants-main.video-participants4
  .participants-view {
  width: 50%;
  height: 50%;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .video-img
  video {
  width: 100%;
  // width: auto;
  object-fit: contain;
  height: 100%;
}
section.videocall-wapper
  .participants-main
  .participants-view.full-screen
  .video-img
  video {
  height: calc(100% - 91px);
  position: relative;
  width: auto;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;

  // top: 50%;
  // left: 50%;
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);

  max-width: 100%;
}

section.videocall-wapper .bottom-menu ul.call-menu li button .icon-camera-off {
  font-size: 16px;
}

video.scale1 {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

video.scale2 {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

video.scale3 {
  -moz-transform: scale(1.75);
  -webkit-transform: scale(1.75);
  -o-transform: scale(1.75);
  -ms-transform: scale(1.75);
  transform: scale(1.75);
}

/* New Changes */
section.videocall-wapper
  .participants-main
  .participants-view
  .pin-video-share {
  position: absolute;
  left: 25px;
  top: 50px;
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 15;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .pin-video-share
  button {
  font-size: 14px;
  margin: 0 5px;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  padding: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
section.videocall-wapper
  .participants-main
  .participants-view
  .pin-video-share
  button.active {
  color: var(--bottom_menu_active_color);
}
section.videocall-wapper
  .participants-main
  .participants-view:hover
  .pin-video-share {
  opacity: 1;
}
/* End New changes */

.disabled-tooltip {
  display: none;
}

/* Participants Screen END */

@media (max-width: 992px) {
  /* Participants Screen */
  section.videocall-wapper .participants-main .participants-view .name {
    color: #ffffff;
    left: 0;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.69));
    box-shadow: none;
    font-size: 12px;
    background: none;
    transform: translate(0%);
    -webkit-transform: translate(0%);
    -moz-transform: translate(0%);
    -o-transform: translate(0%);

    .location-name {
      display: inline-flex;
      flex-flow: wrap;
      .user-name {
        margin: 0 5px 0 0;
      }
    }
  }
  section.videocall-wapper .participants-main.video-participants3 {
    flex-direction: row;
  }

  section.videocall-wapper.call-timer .participants-main {
    .participants-view:nth-child(1) {
      .name {
        margin-left: 80px;
      }
    }
  }

  section.videocall-wapper
    .participants-main.video-participants3
    .participants-view:nth-child(3) {
    height: 50%;
    width: 100%;
  }
  section.videocall-wapper .participants-main.video-participants2 {
    flex-direction: column;
  }
  section.videocall-wapper
    .participants-main.video-participants2
    .participants-view {
    width: 100%;
    height: 50%;
  }
  section.videocall-wapper .participants-main .participants-view .name i {
    margin-right: 5px;
  }
  section.videocall-wapper .participants-main .participants-view .video-img {
    width: 100%;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .name
    .street-view
    button
    i {
    color: #fff;
  }

  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .pin-video
    button {
    opacity: 1;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .pin-video
    button {
    top: 30px;
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: normal;
    padding: 0;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img
    video {
    // object-fit: contain;
    width: auto;
    position: relative;

    // top: 50%;
    // left: 50%;

    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    max-width: 100%;
  }
  /* Participants Screen END*/

  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .live-stream {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: unset;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .live-stream
    video {
    position: relative;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .captured-stream {
    display: none;
  }

  section.videocall-wapper .participants-main .participants-view .video-img {
    .captured-stream {
      margin-left: 20px;
    }
  }

  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .street-view {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img
    video {
    height: auto;
    position: relative;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .live-floorplan {
    height: calc(100% - 91px);
    background-color: white;
    margin-left: 15px;
    position: relative;
  }
  .live-floorplan-video-relative {
    position: relative !important;
  }
  .live-floorplan .inner-measurement {
    height: 100%;
  }
  .live-floorplan.hide {
    display: none;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream {
    height: 100%;
    .live-stream {
      height: calc(100% - 280px);
      width: fit-content !important;
    }
    .captured-stream {
      height: calc(100% - 280px);
      width: fit-content !important;
    }
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .live-stream {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
    overflow: hidden;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img
    .live-stream {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .live-stream
    video {
    position: relative;
    height: 100%;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .captured-stream {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
    padding-left: 15px;
  }
}

section.videocall-wapper
  .participants-main
  .participants-view
  .video-img
  .live-stream {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 91px);
  justify-content: center;

  .generalize-text {
    width: 75%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #fff;
    background-color: var(--bottom_menu_default_bg);
    border-radius: 10px;
  }
}

.connecting-loading {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 22px;
  width: fit-content;
  border-radius: 0 0 5px 5px;
  background: var(--bottom_menu_bg_color);
  color: #fff;

  h4 {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}

.connecting-loading.measurement-loader {
  top: 30px;
  z-index: 1;
  padding: 6px 10px;
}
.connecting-loading.measurement-loader h4 {
  margin: 0 auto;
  font-weight: normal;
}
.connecting-loading.measurement-loader .loader-spin-measurement {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@media (max-width: 767px) {
  section.videocall-wapper .participants-main .participants-view.full-screen {
    .name {
      margin-left: 80px;
    }
  }

  section.videocall-wapper .participants-main .participants-view .video-img {
    .generalize-text {
      width: 100%;
      height: 60%;
      font-size: 22px;
    }
  }

  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img
    .live-stream {
    .generalize-text {
      width: 100%;
      height: 60%;
      font-size: 22px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1240px) {
  .live-stream.canvas-active {
    padding-left: 240px;
  }
}

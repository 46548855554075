/* Connecting Member */
section.videocall-wapper .connecting-member {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  height: 100%;
  width: 100%;
}
section.videocall-wapper .connecting-member .image {
  margin: 0 auto 15px;
  border-radius: 10px;
  line-height: 0;
}
section.videocall-wapper .connecting-member .image img {
  max-height: 125px;
}
section.videocall-wapper .connecting-member .my-prograss {
  width: 300px;
  height: 5px;
  margin: 20px auto;
  background-color: #404040;
  border-radius: 5px;
}
section.videocall-wapper .connecting-member .my-prograss #myBar {
  animation-name: magic;
  animation-duration: 100s;
  width: 95%;
  height: 5px;
  background-color: #334aa6;
  border-radius: 5px;
  @keyframes magic {
    0% {
      width: 0;
    }
    100% {
      width: 95%;
    }
  }
}
section.videocall-wapper .connecting-member h4 {
  color: var(--page_body_text_color);
  font-weight: normal;
  margin: 0;
  font-size: 16px;
}
section.videocall-wapper .connecting-member .site-logo {
  margin: 20px 0 0;
  text-align: center;
}
section.videocall-wapper .connecting-member .site-logo h4 {
  font-weight: 400;
  font-size: 18px;
}

section.videocall-wapper .connecting-member .site-logo img {
  width: 121px;
  height: 28px;
}

.connecting-member {
  .connecting-loading {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 10px 22px;
    width: fit-content;
    border-radius: 0 0 5px 5px;
    background: var(--bottom_menu_bg_color);
    color: #fff;

    h4 {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .powered-by {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 992px) {
  .connecting-member {
    .powered-by {
      position: relative;
      bottom: unset;
      right: unset;
    }
  }
}

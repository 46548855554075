/* Viode Call Wapper */
section.videocall-wapper.modal-open {
  width: calc(100% - 320px);
}
section.videocall-wapper {
  background: var(--page_background_color);
  position: fixed;
  right: 0;
  height: 100%;
  width: 100%;
  // -webkit-transition: all 0.2s linear;
  // -moz-transition: all 0.2s linear;
  // -ms-transition: all 0.2s linear;
  // -o-transition: all 0.2s linear;
  // transition: all 0.2s linear;
}

section.videocall-wapper.thumb-open {
  width: calc(100% - 200px);
  left: 0;
}
/* Viode Call Wapper End*/

/* RECORDING */
.screen-recording {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.69));
  display: flex;
  position: absolute;
  z-index: 11;
  right: 0;
  color: var(--page_body_text_color);
  margin: 0 auto;
  justify-content: center;
  width: 62px;
  bottom: 0;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 0 0 0;
  border-radius: 4px 0 0 0;
}
.screen-recording .point {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  vertical-align: middle;
  background: #ff0000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  text-decoration: blink;
  -webkit-animation-name: blinker-rec;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@keyframes blinker-rec {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* RECORDING END */

@media (max-width: 992px) {
  /* Videocall Wapper CSS*/
  section.videocall-wapper.modal-open {
    width: 100%;
  }

  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .live-stream {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .live-stream
    video {
    position: relative;
  }
  section.videocall-wapper
    .participants-main
    .participants-view.full-screen
    .video-img.live-captured-stream
    .captured-stream {
    display: none;
  }

  /* Videocall Wapper */
}

/* Comman CheckBox */
.check-btn.inline {
  display: inline-block;
  margin: 0 5px;
}
.check-btn.inline span {
  display: inline;
  padding-left: 20px;
}
.check-btn {
  display: inline-block;
  margin: 0 15px 0px 0;
}
.check-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.check-btn input {
  position: absolute;
  left: 0px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.check-btn span {
  padding-left: 25px;
  display: block;
  position: relative;
}
.check-btn span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid var(--sidebar_body_text_color);
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.check-btn input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid var(--sidebar_body_text_color);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (min-width: 992px) {
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream {
    height: 100%;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .live-stream {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .live-stream
    video {
    position: relative;
    height: 100%;
  }
  section.videocall-wapper
    .participants-main
    .participants-view
    .video-img.live-captured-stream
    .captured-stream {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    justify-content: center;
  }
}

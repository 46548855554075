.attach-document {
  display: flex;
  flex-flow: column;

  .attach-button {
    text-align: center;
    margin: 0 -10px;
    padding: 0 0 10px;
  }
  .attach-list {
    height: 100%;
    overflow-y: auto;
  }
}

.attach-document .attach-button .blue-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.attach-document .attach-button .loader-spin {
  margin-left: 7px;
  width: 18px;
  height: 18px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
}

.attach-document .attach-list {
  margin: 0 -10px;
  padding: 10px;
}
.attach-document .attach-list .box {
  padding: 10px;
  border: 1px solid var(--sidebar_boder_color);
  margin-bottom: 10px;
  border-radius: 7px;
}
.attach-document .attach-list .box .title {
  display: flex;
  word-break: break-all;
}
.attach-document .attach-list .box .title i {
  margin-right: 15px;
  font-size: 18px;
}
.attach-document .attach-list .box .bottom-list {
  margin: 10px 0 0;
  display: flex;
}
.attach-document .attach-list .box .bottom-list .rigit-info {
  margin-left: auto;
  position: relative;
}

.attach-document .attach-list .box .bottom-list .rigit-info .time {
  display: inline-flex;
  opacity: 0.4;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.attach-document .attach-list .box .bottom-list .rigit-info .time i {
  font-size: 18px;
  width: 30px;
}
.attach-document .attach-list .box .bottom-list .user {
  display: inline-flex;
  opacity: 0.4;
}
.attach-document .attach-list .box .bottom-list .user i {
  font-size: 18px;
  width: 30px;
}
.attach-document .attach-list .box .bottom-list .delete-down {
  margin-left: auto;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: all 0.3s;
}
.attach-document .attach-list .box:hover .bottom-list .delete-down {
  opacity: 1;
}
.attach-document .attach-list .box .bottom-list .delete-down button {
  padding: 0 5px;
  color: var(--sidebar_body_text_color);
  font-size: 18px;
}

.attach-document .attach-list .box:hover .bottom-list .rigit-info .time {
  opacity: 0;
}

.attach-document .attach-button .drop-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px dashed var(--sidebar_body_text_color);
  padding: 9px 15px;
  color: var(--sidebar_body_text_color);
  border-radius: 30px;
}
.attach-document .attach-button .drop-btn .icon-attach {
  font-size: 16px;
  margin-right: 5px;
}
.attach-document .attach-button .loader-spin {
  margin-left: 7px;
  width: 18px;
  height: 18px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
}

.loader-spin {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: inline-block;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.attach-document .attach-list .no-attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

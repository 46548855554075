/* Start Appraisal */
.start-appraisal-modal .radio-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.start-appraisal-modal .radio-btn input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.start-appraisal-modal .radio-btn span {
  padding-left: 30px;
  display: block;
  position: relative;
}
.start-appraisal-modal .radio-btn span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--sidebar_body_text_color);
  border-radius: 50%;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.start-appraisal-modal .radio-btn input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  right: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--sidebar_body_text_color);
}
.start-appraisal-modal .capture-image {
  color: var(--sidebar_body_text_color);
  padding: 0;
}
.start-appraisal-modal .radio-btn {
  display: inline-block;
  margin: 0 15px 0 0;
}
.start-appraisal-modal .capture-image i {
  margin-right: 10px;
}
.start-appraisal-modal .appriser-bookmark .search-view {
  position: relative;
}
.start-appraisal-modal .appriser-bookmark .search-view button.search {
  position: absolute;
  top: 11px;
  right: 6px;
  font-size: 14px;
  color: var(--sidebar_body_text_color);
}
.start-appraisal-modal .appriser-bookmark .search-view input[type="search"] {
  border-radius: 0;
}
.start-appraisal-modal .appriser-bookmark .search-view button.create-book {
  background: var(--primary_button_fill_color);
  padding: 10px 20px;
  display: block;
  width: 100%;
  color: var(--primary_button_text_color);
  margin: 5px 0 0;
}
.start-appraisal-modal .appriser-bookmark .bookmark-list {
  margin: 10px -10px;
  overflow-y: auto;
  height: calc(100vh - 500px);
}
.start-appraisal-modal .appriser-bookmark .bookmark-list ul {
  padding: 0;
  margin: 0;
}
.start-appraisal-modal .appriser-bookmark .bookmark-list ul li {
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.start-appraisal-modal .appriser-bookmark .bookmark-list ul li button {
  color: var(--sidebar_body_text_color);
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.start-appraisal-modal .appriser-bookmark .bookmark-list ul li button:hover {
  background: var(--bottom_menu_hover_color);
}

.start-appraisal-modal .no-bookmark {
  height: calc(100vh - 440px);
  display: flex;
  text-align: center;
  align-items: center;
  color: var(--sidebar_body_text_color);
}

.start-appraisal-modal .side-toggle {
  background: var(--primary_button_fill_color);
  color: var(--primary_button_text_color);
  position: absolute;
  right: -30px;
  top: 140px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 22px;
  padding: 0;
  justify-content: center;
  border-radius: 0 5px 5px 0;
}

.start-appraisal-modal .appriser-bookmark .bookmark-list.active {
  height: calc(100vh - 533px);
}
/* Start tab Appraisal */
.start-appraisal-tab-modal.comman-modal .comman-modal-main .comman-modal-body {
  padding: 10px 10px 0;
}
.start-appraisal-tab-modal .custom-tab {
  height: 99%;
}
.start-appraisal-tab-modal .custom-tab .tab-content {
  height: calc(100% - 42px);
}
.start-appraisal-tab-modal .custom-tab .tab-content .appriser-bookmark {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.start-appraisal-tab-modal .radio-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.start-appraisal-tab-modal .radio-btn input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.start-appraisal-tab-modal .radio-btn span {
  padding-left: 30px;
  display: block;
  position: relative;
}
.start-appraisal-tab-modal .radio-btn span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--sidebar_body_text_color);
  border-radius: 50%;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.start-appraisal-tab-modal .radio-btn input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  right: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--sidebar_body_text_color);
}
.start-appraisal-tab-modal .capture-image {
  color: var(--sidebar_body_text_color);
  padding: 0;
}
.start-appraisal-tab-modal .radio-btn {
  display: inline-block;
  margin: 0 15px 0 0;
}
.start-appraisal-tab-modal .capture-image i {
  margin-right: 10px;
}
.start-appraisal-tab-modal .measurement-form {
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.start-appraisal-tab-modal .measurement-form .measurement-form-control {
  height: 100%;
}
.start-appraisal-tab-modal .appriser-bookmark .search-view {
  position: relative;
}
.start-appraisal-tab-modal .appriser-bookmark .search-view button.search {
  position: absolute;
  top: 11px;
  right: 6px;
  font-size: 14px;
  color: var(--sidebar_body_text_color);
}
.start-appraisal-tab-modal
  .appriser-bookmark
  .search-view
  input[type="search"] {
  border-radius: 30px;
}
.start-appraisal-tab-modal .appriser-bookmark .search-view button.create-book {
  background: var(--primary_button_fill_color);
  padding: 10px 20px;
  display: block;
  width: 100%;
  color: var(--primary_button_text_color);
  margin: 5px 0 0;
}
.start-appraisal-tab-modal .appriser-bookmark .bookmark-list {
  margin: 10px -10px 0;
  overflow-y: auto;
  height: 100%;
}
.start-appraisal-tab-modal .appriser-bookmark .bookmark-list.active {
}
.start-appraisal-tab-modal .appriser-bookmark .bookmark-list ul {
  padding: 0;
  margin: 0;
}
.start-appraisal-tab-modal .appriser-bookmark .bookmark-list ul li {
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.start-appraisal-tab-modal .appriser-bookmark .bookmark-list ul li button {
  color: var(--sidebar_body_text_color);
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.start-appraisal-tab-modal
  .appriser-bookmark
  .bookmark-list
  ul
  li
  button:hover {
  background: var(--bottom_menu_hover_color);
}

.start-appraisal-tab-modal
  .appriser-bookmark
  .bookmark-list
  ul
  li
  button:active {
  background: var(--sidebar_boder_color);
}
.start-appraisal-tab-modal .no-bookmark {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  color: var(--sidebar_body_text_color);
}
.start-appraisal-tab-modal .side-toggle {
  background: var(--primary_button_fill_color);
  color: var(--primary_button_text_color);
  position: absolute;
  right: -30px;
  top: 120px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 22px;
  padding: 0;
  justify-content: center;
  border-radius: 0 5px 5px 0;
}
/* End Start tab Appraisal */

/* Custom Tab */
.custom-tab {
  position: relative;
}
.custom-tab .right-side {
  position: absolute;
  right: -15px;
  top: -62px;
}
.custom-tab .tab-list {
  padding: 0;
  margin: 0 -15px 15px;
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);
  padding: 0 10px 5px;
}
.custom-tab .tab-list li {
  display: inline-block;
  padding-right: 12px;
}
.custom-tab .tab-list li button {
  font-weight: 600;
  position: relative;
  color: #ffa500;
  opacity: 0.7;
  font-size: 12px;
  padding: 0;
}
.custom-tab .tab-list li button i {
  margin-right: 5px;
}
.custom-tab .tab-list li.active button {
  color: #ffa500;
  opacity: 1;
}
.custom-tab .tab-list li.active button:after {
  content: "";
  border-bottom: 1px solid #ffa500;
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
}
.custom-tab .tab-content {
  display: none;
}
.custom-tab .tab-content.active {
  display: block;
}
/*measurement instruction*/
.measurement-instructions {
  background: var(--bottom_menu_hover_color);
  margin: 0 -10px 0;
  padding: 10px;
}
.instructions-list {
  padding: 0 0 0 15px;
}

.instructions-button-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.instructions-button-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.start-appraisal-tab-modal .instructions-button-list {
  position: relative;
}
.start-appraisal-tab-modal .instructions-button-list ul li {
  margin: 0 -10px;
}
.instructions-button-list ul li {
  border-top: 1px solid var(--sidebar_boder_color);
}
.instructions-button-list ul li button {
  color: #ffa500 !important;
  display: flex;
  width: 100%;
  padding: 10px 10px;
  align-items: center;
  font-size: 18px;
}
.instructions-button-list ul li button:hover {
  background: var(--bottom_menu_hover_color);
}
.instructions-button-list ul li button:active {
  background-color: var(--sidebar_boder_color);
}
.instructions-button-list ul li button i {
  width: 32px;
  text-align: left;
}
.instructions-button-list ul li button .text {
  font-size: 14px;
}
.instructions-button-list ul li button.active {
  color: var(--bottom_menu_active_color);
}
.instructions-button-list ul li button .icon-close-image {
  color: #ff0000;
}

.measurement-instructions {
  background: var(--bottom_menu_hover_color);
  margin: 0 -10px 0;
  padding: 10px;
}
.instructions-list {
  padding: 0 0 0 15px;
}

/* Custom Dropdown */
.cutom-dropdown {
  position: relative;
}
.cutom-dropdown.active .choose-item {
  display: block;
}
.cutom-dropdown .selected-item {
  position: relative;
}
.cutom-dropdown .selected-item .drop-aero {
  position: absolute;
  color: #fff;
  top: 17px;
  right: 6px;
  font-size: 6px;
}
.cutom-dropdown .selected-item button {
  font-family: "Roboto", sans-serif;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  background-clip: padding-box;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--sidebar_body_text_color);
  text-align: left;
}
.cutom-dropdown .choose-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
}
.cutom-dropdown .choose-item ul.choose-item-list {
  background: var(--sidebar_bg_color);
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 0.25rem;
  max-height: 200px;
  overflow: auto;
}
.cutom-dropdown .choose-item ul.choose-item-list li.seprator-li {
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.cutom-dropdown .choose-item ul.choose-item-list li {
  width: 100%;
}
.cutom-dropdown .choose-item ul.choose-item-list li button:hover {
  background-color: var(--bottom_menu_hover_color);
}
.cutom-dropdown .choose-item ul.choose-item-list li button {
  font-family: "Roboto", sans-serif;
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--sidebar_body_text_color);
  text-align: left;
}

.MuiButtonBase-root .MuiButton-root {
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
}

.select-box-div select {
  width: 100%;
  height: 40px;
  border: 1px solid #6c6c6c;
  padding: 0 0 0 10px;
  font-size: 15px;
  color: #000;
}
.select-box-div select::-webkit-scrollbar {
  width: 4px;
  background-color: #dddddd;
}
.select-box-div select::-webkit-scrollbar-thumb {
  background-color: #b5b4b4;
  border-radius: 0px;
}

.auto-snapping-form {
  display: flex;
  align-items: center;
}
.auto-snapping-form .form-text {
  font-size: 14px;
  font-weight: 500;
}
.auto-snapping-form .switch-button .toggle .toggle-box {
  display: flex;
}
.auto-snapping-form .switch-button .toggle .toggle-box .radio-button {
  padding: 2px 2px;
  margin: 0;
}

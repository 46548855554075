#pointerCanvas {
  position: absolute;
  z-index: 19;
  width: auto;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  cursor: none;
}

@media (max-width: 568px) {
  
  #pointerCanvas {
    max-width: unset;
  }

}
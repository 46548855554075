/* Alert Messages and Error Message*/
.alert-box { padding: 0 10px; position: absolute; top: 0; border-radius: 30px; left: 50%; transform: translate(-50%, 20px); color: #fff; display: flex;  justify-content: center;z-index: 20;}
.alert-box .box{  padding: 10px; border-radius: 5px; position: relative; display: flex; align-items: center; font-size: 14px; max-width: 440px; }
.alert-box .close-modal{ color: #fff; }
.alert-box .box.upload-img{ background: rgb(0 0 0 / 50%); box-shadow: 0px 0px 13px 0px #404040; }
.alert-box .box.upload-img .close-modal img{ width: 20px; }
/* Alert Messages and Error Message*/

@media (max-width: 414px) {

    .alert-box{
        width: 80%;
    }

}
section.videocall-wapper .bottom-menu {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  z-index: 20;
  display: table;
  margin: 0 auto;
  bottom: -82px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
section.videocall-wapper .bottom-menu .inner-bottom {
  position: relative;
  text-align: center;
}
section.videocall-wapper .bottom-menu ul.list-menu {
  display: table;
  margin: 0 auto;
  padding: 0 20px;
  background: var(--sidebar_bg_color);
  border-radius: 108px;
}
section.videocall-wapper .bottom-menu ul.list-menu li {
  list-style: none;
  text-align: center;
  display: inline-block;
}
section.videocall-wapper .bottom-menu ul.list-menu li.active button {
  color: var(--bottom_menu_active_color);
}
section.videocall-wapper .bottom-menu ul.list-menu li.disable button {
  color: var(--bottom_menu_text_color);
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}
section.videocall-wapper .bottom-menu ul.list-menu li button {
  border: 0;
  padding: 12px;
  background: none;
  border: none;
  outline: none;
  color: var(--bottom_menu_text_color);
  font-size: 14px;
  cursor: pointer;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .text {
  font-size: 13px;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .icon {
  line-height: 26px;
  position: relative;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .icon .notified {
  position: absolute;
  font-size: 12px;
  line-height: normal;
  left: calc(50% + 8px);
  right: 0;
  width: 20px;
  position: absolute;
  line-height: normal;
  text-align: left;
}
section.videocall-wapper .bottom-menu ul.list-menu li button:focus {
  outline: none;
  box-shadow: none;
}
section.videocall-wapper .bottom-menu ul.call-menu {
  padding: 0;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.videocall-wapper .bottom-menu ul.call-menu li {
  list-style: none;
  display: inline-block;
  padding: 0 7px;
  vertical-align: middle;

  &.capture-image {
    .icon {
      font-size: 16px;
    }
  }

  &.chat-main {
    button {
      position: relative;
      .icon {
        line-height: 0;
        .icon-bookmark {
          font-size: 19px;
        }
        .icon-chat {
          font-size: 17px;
        }
        .notified {
          position: absolute;
          min-width: 20px;
          height: 20px;
          background-color: var(--bottom_menu_active_color);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          top: -3px;
          right: -3px;
        }
      }
    }
  }
  &.active {
    button {
      color: var(--bottom_menu_active_color);
    }
  }
  &.opacity {
    opacity: 0.4;
  }
  &.disable {
    opacity: 0.4;
    pointer-events: none;
  }
}
section.videocall-wapper .bottom-menu ul.call-menu li button {
  padding: 0;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  color: #fff;
  background: var(--bottom_menu_default_bg);
  cursor: pointer;
  border: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #222222;

  &:hover {
    background: var(--bottom_menu_bg_color);
  }
  &:active {
    background: var(--sidebar_boder_color);
  }
}
section.videocall-wapper .bottom-menu ul.call-menu li button .icon-stop-video {
  font-size: 20px;
}
section.videocall-wapper .bottom-menu ul.call-menu li button .icon-mute-voice {
  font-size: 22px;
}

section.videocall-wapper .bottom-menu ul.call-menu li.start-inspection {
  &.disable {
    button {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  button {
    width: 65px;
    height: 65px;
    color: #ffa500;
    .icon {
      line-height: 0;
      .icon-measurement1 {
        font-size: 22px;
      }
    }
  }
}

section.videocall-wapper .bottom-menu ul.call-menu li.disconnected button {
  color: #fff;
  font-size: 12px;
  background: #fe1743;
  &:hover {
    background: #d20028;
  }
}
section.videocall-wapper .bottom-menu ul.call-menu li.mice button {
  font-size: 20px;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more {
  display: none;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more button {
  font-size: 8px;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more .count {
  position: absolute;
  top: -6px;
  right: 0;
  font-size: 13px;
  color: #fff;
  line-height: normal;
  font-weight: 600;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more.notified button {
  background: var(--bottom_menu_bg_color);
  color: #fff;
}
section.videocall-wapper .bottom-menu ul.list-menu li button:hover {
  background-color: var(--sidebar_boder_color);
}
section.videocall-wapper .bottom-menu ul.list-menu li button:active {
  background: var(--bottom_menu_hover_color);
}

section.videocall-wapper .pointer-mobile {
  display: none;
}
section.videocall-wapper .pointer-mobile button {
  background: var(--bottom_menu_bg_color);
  color: var(--bottom_menu_text_color);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  position: absolute;
  right: 15px;
  z-index: 20;
  bottom: 80px;
}
section.videocall-wapper .pointer-mobile button.active {
  color: var(--bottom_menu_active_color);
}

section.videocall-wapper .pointer-mobile.disable button {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

/* Bottom Menu */
section.videocall-wapper .bottom-menu {
  bottom: 0;
}
section.videocall-wapper .bottom-menu ul.call-menu {
  position: unset;
  margin: 10px 0;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more button {
  display: inline-block;
}
section.videocall-wapper .bottom-menu ul.list-menu.open {
  opacity: 1;
  visibility: visible;
}
section.videocall-wapper .bottom-menu ul.list-menu {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  bottom: 100%;
  display: block;
  min-height: 40px;
  max-width: 200px;
  position: absolute;
  right: 68px;
  border-radius: 5px;
  padding: 10px 0;
  margin: 0 auto 10px;

  .displayMobileView {
    display: none;
  }
}

section.videocall-wapper .bottom-menu ul.list-menu li {
  text-align: left;
  display: block;
}
section.videocall-wapper .bottom-menu ul.list-menu li button {
  display: inline-block;
  width: 100%;
  text-align: left;
  vertical-align: middle;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .icon {
  display: inline-block;
  width: 28px;
  line-height: 18px;
  vertical-align: middle;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .icon .notified {
  top: -12px;
  left: 13px;
  right: unset;
}
section.videocall-wapper .bottom-menu ul.list-menu li button .text {
  display: inline-block;
}
section.videocall-wapper .bottom-menu ul.call-menu li {
  padding: 0 5px;
}
section.videocall-wapper .bottom-menu ul.call-menu li.more {
  display: inline-block;
}

section.videocall-wapper .bottom-menu ul.call-menu li.start-inspection button {
  width: 65px;
  height: 65px;
  color: #fff;
  background: var(--green_button_bg);
}

section.videocall-wapper .bottom-menu ul.call-menu li.flash-image .icon {
  font-size: 20px;
}

section.videocall-wapper .bottom-menu ul.call-menu li.flash-image .icon i {
  margin-top: 2px;
  display: inline-block;
}

/* Bottom Menu END */

@media (max-width: 480px) {
  .inspection-form .room-image {
    margin: 0 -10px 10px;
  }
  .inspection-form .room-image .box {
    padding: 0 10px;
  }
  .map-view-modal .map-view-inner {
    max-width: calc(100% - 10%);
  }
}

@media (max-width: 1199px) {
  section.videocall-wapper .bottom-menu ul.list-menu li.pointer {
    display: none;
  }
  section.videocall-wapper .pointer-mobile {
    display: inline-block;
  }

  section.videocall-wapper .bottom-menu ul.list-menu {
    max-height: 200px;
    padding: 0;

    li {
      button {
        padding: 10px 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  section.videocall-wapper .bottom-menu {
    bottom: 10px;
  }

  section.videocall-wapper .bottom-menu ul.call-menu {
    .display-web-view {
      display: none;
    }
  }

  section.videocall-wapper .bottom-menu ul.list-menu {
    .displayMobileView {
      display: block;
    }
  }
}

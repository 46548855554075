/* Add Participant */
.participant-from textarea {
  resize: none;
}
#add-participant .modal-area {
  overflow-y: auto;
  height: calc(100% - 42px);
}

.participant-from .radio-group {
  display: flex;
  margin: 0 0 10px;
}
.participant-from .radio-btn {
  width: 50%;
}
.participant-from .radio-btn label {
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
.participant-from .radio-btn input {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.participant-from .radio-btn span {
  padding-left: 30px;
  display: block;
  position: relative;
}
.participant-from .radio-btn span:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--sidebar_body_text_color);
  border-radius: 50%;
  transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
}
.participant-from .radio-btn input:checked ~ span:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  right: 0px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--sidebar_body_text_color);
}

.country-drpdown {
  display: flex;
  width: 100%;
  border: 1px solid #cacaca;
}
.country-drpdown .react-tel-input {
  width: 80px;
}
.country-drpdown .react-tel-input .flag-dropdown {
  border: none;
  background-color: unset;
}
.country-drpdown .react-tel-input .form-control {
  width: 90px;
  border: none;
  height: 40px;
  border-radius: 0;
  padding-right: 0;
}
.country-drpdown .react-tel-input ~ input {
  border: none;
}

.button-group {
  display: flex;
  justify-content: center;
  .invite-participant {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 16px;
    }

    .loader-spin {
      margin-left: 7px;
      width: 18px;
      height: 18px;
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
    }
  }
}
/* Add Participant END*/

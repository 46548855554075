/* MAP VIEW CSS */
.map-view-modal:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 12;
}
.map-view-modal .map-view-inner {
  background: var(--page_background_color);
  max-width: calc(100% - 40%);
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 13;
  box-shadow: 0 0 6px rgb(32 33 36 / 28%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.map-view-modal .map-view-inner .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #d1d1dd;
  color: var(--page_body_text_color);
}
.map-view-modal .map-view-inner .modal-header .modal-title {
  display: flex;
  align-items: center;
}
.map-view-modal .map-view-inner .modal-header .modal-title h3 {
  margin: 0;
}
.map-view-modal .map-view-inner .modal-header .model_button {
}
.map-view-modal .map-view-inner .modal-header .model_button i {
  margin-left: 10px;
}
.map-view-modal .map-view-inner .modal-body {
  width: 100%;
  height: calc(100vh - 100px);
}
.map-view-modal .map-view-inner .modal-header .model_button button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: var(--page_body_text_color);
}
.map-view-modal .map-view-inner .modal-header .model_button button:focus {
  border: none;
  outline: none;
}

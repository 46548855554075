.error-info .retry {
  justify-content: flex-end;
  display: flex;
}

.model-abtn {
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.retry .btnDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.model-loader-spin {
  margin-left: 5px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: inline-block;
}

/* NOTE Liste CSS */

.side-head .refresh {
  color: #fff;
  font-size: 15px;
}
.notes-view {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
  margin: 0 -10px;

  .no-notes {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.notes-view .add-note {
  padding: 0px 0 10px;
  text-align: center;
  margin: 0 -10px;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.notes-view .note-list {
  margin: 0 0 10px 0;
  padding: 10px;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 7px;
}
.notes-view .note-list .left-image {
  float: left;
  margin-right: 10px;
  color: #fff;
}
.notes-view .note-list .text {
  overflow: hidden;
}
.notes-view .note-list .text .title {
  margin: 0 0 5px;
}
.notes-view .note-list.active {
  background: var(--bottom_menu_hover_color);
}
.notes-view .note-list .text input[type="text"] {
  background: none;
  border: 0;
  padding: 0;
  width: 100%;
  outline: none;
  color: #fff;
  font-size: 16px;
}
.notes-view .note-list .text .form-control {
  background: none;
  border: none;
  resize: none;
  padding: 0;
  overflow: hidden;
}
.notes-view .note-list .text .text-editable {
  position: relative;
  outline: none;
}
.notes-view .note-list .text .text-editable span {
  color: #757575;
  position: absolute;
}
/*.notes-view .note-list .text .textarea { display: grid; }
.notes-view .note-list .text .textarea:after{ content: attr(data-replicated-value) " "; white-space: pre-wrap;  visibility: hidden; }
.notes-view .note-list .text .textarea:after, .notes-view .note-list .text .form-control{ grid-area: 1 / 1 / 2 / 2; word-break: break-all; }*/

.notes-view .note-list .bottom-list {
  margin: 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notes-view .note-list .bottom-list .bottom-btn {
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.notes-view .note-list:hover .bottom-list .bottom-btn {
  opacity: 1;
}

.notes-view .note-list .bottom-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.notes-view .note-list .bottom-btn button {
  color: var(--sidebar_body_text_color);
  font-size: 16px;
  padding: 0;
  margin: 0 6px;
}
.notes-view .note-list .bottom-btn .gray-btn {
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  background: var(--secondary_button_fill_color);
  color: var(--secondary_button_text_color);
}
.notes-view .note-list .bottom-btn .delete {
  padding: 0;
}
.notes-view .note-list .time {
  opacity: 0.4;
  font-size: 14px;
}
.notes-view .note-list .time i {
  margin-right: 10px;
}

.notes-view .note-list .text .text-area .note-area {
  display: grid;
  word-break: break-all;
}
.notes-view .note-list .text .text-area .note-area::after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.notes-view .note-list .text .text-area .note-area > textarea {
  resize: none;
  overflow: hidden;
}
.notes-view .note-list .text .text-area .note-area > textarea,
.notes-view .note-list .text .text-area .note-area::after {
  color: #fff;
  padding: 2px 0;
  border: 0;
  background: transparent;
  outline: none;
  font: inherit;
  grid-area: 1 / 1 / 2 / 2;
}

.notes-view .note-list .time {
  opacity: 0.4;
  font-size: 14px;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.notes-view .note-list .time i {
  margin-right: 5px;
}
.notes-view .note-list .user {
  opacity: 0.4;
}
.notes-view .note-list .user i {
  margin-right: 8px;
}
.notes-view .note-list .bottom-list .bottom-btn .edit-del {
  opacity: 0;
  position: absolute;
  right: 0px;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.notes-view .note-list:hover .bottom-list .bottom-btn .time {
  opacity: 0;
}

.notes-view .note-list:hover .bottom-list .bottom-btn .edit-del {
  opacity: 1;
}
/* NOTE Liste CSS END*/

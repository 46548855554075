/* New Before Joining Modal  */
section.videocall-wapper .new-before-joining-modal {
  color: var(--page_body_text_color);
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  justify-content: center;
}
section.videocall-wapper .new-before-joining-modal .form-control {
  color: var(--page_body_text_color);
  text-align: center;
}
section.videocall-wapper .new-before-joining-modal .email-view {
  padding: 20px;
  border-radius: 10px;
  max-width: 460px;
  width: 100%;
  margin: 10px;
  text-align: center;
}
section.videocall-wapper .new-before-joining-modal .btn-link {
  color: #3d63f9;
  padding: 0;
  font-size: 14px;
}
section.videocall-wapper .new-before-joining-modal .btn-link:hover {
  text-decoration: underline;
}
/* New Before Joining Modal  END*/
/* Custom Dropdown */
.cutom-dropdown {
  position: relative;
}
.cutom-dropdown.active .choose-item {
  display: flex;
}
.cutom-dropdown .selected-item {
  position: relative;
}
.cutom-dropdown .selected-item .flag-icon {
  display: inline-block;
  margin-right: 10px;
  line-height: 0;
}
.cutom-dropdown .selected-item .flag-icon img {
  height: 30px;
}
.cutom-dropdown .selected-item .drop-aero {
  position: absolute;
  color: #fff;
  top: 17px;
  right: 6px;
  font-size: 6px;
}
.cutom-dropdown .selected-item button {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  background-clip: padding-box;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--sidebar_body_text_color);
  text-align: left;
}
.cutom-dropdown .choose-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  max-height: 200px;
  overflow: auto;
}
.cutom-dropdown .choose-item ul.choose-item-list {
  background: var(--sidebar_bg_color);
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 0.25rem;
}
.cutom-dropdown .choose-item ul.choose-item-list li {
  width: 100%;
}
.cutom-dropdown .choose-item ul.choose-item-list li button:hover {
  background-color: var(--bottom_menu_hover_color);
}
.cutom-dropdown .choose-item ul.choose-item-list li button {
  font-family: "Roboto", sans-serif;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  padding: 10px 15px;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background: transparent;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--sidebar_body_text_color);
  text-align: left;
}
.cutom-dropdown .choose-item ul.choose-item-list li button .flag-icon {
  display: inline-block;
  margin-right: 10px;
  line-height: 0;
}
.cutom-dropdown .choose-item ul.choose-item-list li button .flag-icon img {
  height: 30px;
}
.language-view .text-left {
  width: 100%;
}
ul.choose-item-list li button {
  display: flex !important;
}
.selected-item button {
  display: flex !important;
}

/* Bookmark View */
.bookmark-view {
}
.bookmark-view .search-view {
  position: relative;
}
.bookmark-view .search-view button.search {
  position: absolute;
  top: 11px;
  right: 6px;
  font-size: 14px;
  color: var(--sidebar_body_text_color);
}
.bookmark-view .search-view input[type="search"] {
  border-radius: 30px;
}
.bookmark-view .search-view button.create-book {
  background: var(--primary_button_fill_color);
  padding: 10px 20px;
  display: block;
  width: 100%;
  color: var(--primary_button_text_color);
}
.bookmark-view .no-bookmark {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--sidebar_body_text_color);
}

.bookmark-view .bookmark-list {
  margin: 10px -10px;
  overflow-y: auto;
  height: calc(100vh - 140px);
}
.bookmark-view .bookmark-list ul {
  padding: 0;
  margin: 0;
}
.bookmark-view .bookmark-list ul li {
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.bookmark-view .bookmark-list ul li button {
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: var(--sidebar_body_text_color);
}
.bookmark-view .bookmark-list ul li button:hover {
  background: var(--bottom_menu_hover_color);
}

.comman-modal .comman-modal-main .comman-modal-body.bookmark-modal-body {
  padding-bottom: 0;
}
.comman-modal
  .comman-modal-main
  .comman-modal-body.bookmark-modal-body
  .bookmark-view {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.comman-modal
  .comman-modal-main
  .comman-modal-body.bookmark-modal-body
  .bookmark-view
  .bookmark-list {
  margin-bottom: 0;
}

.search-view ul.search-dropdown {
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 10px;
  margin-top: 5px;
}
.search-view ul.search-dropdown li {
  list-style: none;
  border-bottom: 1px solid var(--sidebar_boder_color);

  &.sticky-button {
    position: sticky;
    bottom: 0;
    border-bottom: 0;
  }
}
.search-view ul.search-dropdown li button {
  padding: 8px 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: var(--sidebar_body_text_color);
}
.search-view ul.search-dropdown li button:hover {
  background: var(--bottom_menu_hover_color);
}

.start-appraisal-tab-modal
  .appriser-bookmark
  .bookmark-list
  ul.selected-bookmarklabel
  li {
  display: inline-flex;
  border-bottom: 0;
  margin: 0 0 10px 10px;
}

.start-appraisal-tab-modal
  .appriser-bookmark
  .bookmark-list
  ul.selected-bookmarklabel
  li
  button {
  background-color: var(--sidebar_boder_color);
  color: var(--sidebar_body_text_color);
  padding: 8px 10px;
  width: fit-content;
  text-align: left;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
}

.start-appraisal-tab-modal
  .appriser-bookmark
  .bookmark-list
  ul.selected-bookmarklabel
  li
  button
  span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--bottom_menu_bg_color);
  margin-left: 5px;
}

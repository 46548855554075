/* Top Right Menu */

section.videocall-wapper .top-right-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
}
section.videocall-wapper .top-right-menu ul {
  margin: 0;
  padding: 0;
}
section.videocall-wapper .top-right-menu ul li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
}
section.videocall-wapper .top-right-menu ul li button {
  font-size: initial;
  background: var(--bottom_menu_bg_color);
  color: var(--bottom_menu_text_color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
section.videocall-wapper .top-right-menu ul li button:hover {
  background: var(--page_background_color);
}

section.videocall-wapper .top-right-menu ul li button:active {
  background: var(--sidebar_boder_color);
  color: var(--bottom_menu_text_color);
}

section.videocall-wapper .top-right-menu ul li.active button {
  color: var(--bottom_menu_active_color);
}

section.videocall-wapper .top-right-menu ul li button .icon-questionnaire {
  font-size: 24px;
  line-height: 37px;
}
section.videocall-wapper .top-right-menu ul li button .icon-inspection-form {
  font-size: 20px;
  line-height: 38px;
}
section.videocall-wapper .top-right-menu ul li.filp-camera {
  display: none;
}
section.videocall-wapper .top-right-menu ul li.filp-camera.disable button {
  color: #666;
}
section.videocall-wapper .top-right-menu ul li.filp-camera button {
  color: #fff;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px rgba(244, 244, 244, 0.25);
}

@media (max-width: 1199px) {
  /* Top Right Menu */
  section.videocall-wapper .top-right-menu ul li.inspection-btn {
    display: none;
  }
  section.videocall-wapper .top-right-menu ul li.filp-camera {
    display: inline-block;
  }
  section.videocall-wapper .top-right-menu ul {
    display: flex;
    flex-flow: column-reverse;
  }
  section.videocall-wapper .top-right-menu ul li {
    padding: 2px 1px;
  }
  section.videocall-wapper .top-right-menu ul li button {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 10px;
  }
  section.videocall-wapper .top-right-menu ul li button .icon-questionnaire {
    font-size: 16px;
    line-height: 29px;
  }
  section.videocall-wapper .top-right-menu ul li button .icon-inspection-form {
    font-size: 14px;
    line-height: 29px;
  }
  /* Top Right Menu END*/
}

@media (max-width: 480px) {
  section.videocall-wapper .top-right-menu ul li {
    padding: 0 1px;
  }
  section.videocall-wapper .top-right-menu ul li button {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 10px;
  }
  section.videocall-wapper .top-right-menu ul li button .icon-questionnaire {
    font-size: 16px;
    line-height: 29px;
  }
  section.videocall-wapper .top-right-menu ul li button .icon-inspection-form {
    font-size: 14px;
    line-height: 29px;
  }
}

/* Participants View */
.parti-modal-view {
  margin: 0 -10px;
  padding: 0 10px;
}
.parti-modal-view .invite-participent {
  padding: 0px 0 10px;
  text-align: center;
  margin: 0 -10px;
  border-bottom: 1px solid var(--sidebar_boder_color);
}
.parti-modal-view .participants-list {
  padding: 10px 10px 0;
  margin: 0 -10px;
}
.parti-modal-view .participants-list .head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid var(--sidebar_boder_color);
  border-radius: 7px;
}
.parti-modal-view .participants-list .head .icn-name i {
  font-size: 12px;
  margin-right: 7px;
}

.parti-modal-view .participants-list .head .network-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.parti-modal-view .participants-list .head .network-list ul li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  padding: 0 0 0 6px;

  button {
    color: #fff;
    padding: 0 3px;
  }
}
.parti-modal-view .participants-list .head .network-list ul li .icon-video {
  font-size: 10px;
}
.parti-modal-view
  .participants-list
  .head
  .network-list
  ul
  li
  .icon-arrow-down {
  font-size: 9px;
}

/* Participants View END */

.MuiPopover-root {
  .MuiPaper-root {
    color: #fff;
    background-color: var(--bottom_menu_hover_color);
    padding: 10px 8px;
    &.MuiPopover-paper {
      p {
        margin: 0;
        opacity: 0.6;
        font-size: 14px;
        margin-bottom: 6px;
      }
    }
  }
}

/* Header */
header.po-header { text-align: center; padding: 10px 15px; background: var(--header_footer_bg); color: var(--header_text_color); }
header.po-header img{ height: 45px; }

/* App Link And Virtual Call */
section.po-wapper { background: var(--body_background_color); display: flex; align-items: center; flex-wrap: wrap; overflow-y: auto; height: calc(100vh - 105px); }
section.po-wapper.non-footer { height: calc(100vh - 53px); }
section.po-wapper .po-content{ padding: 15px; width: 100%; }
section.po-wapper .po-content .box{ background: var(--header_footer_bg); max-width: 400px; text-align: center; border-radius: 5px; margin: 0 auto; }
section.po-wapper .po-content .box .property-details{ padding: 15px; border-bottom: 1px solid #AFAFAF; }
section.po-wapper .po-content .box .watch-video{ padding: 15px; }
section.po-wapper .po-content .box .watch-video .app-icon img{ height: 35px; }

/* Questionnaire Form */
section.po-wapper .po-content .questionnaire-form { background: var(--header_footer_bg); color: var(--body_text_color); max-width: 400px; border-radius: 5px; margin: 0 auto; }
section.po-wapper .po-content .questionnaire-form .panel-head{ padding: 10px; border-bottom: 1px solid #BBBBBB; font-weight: 500; }
section.po-wapper .po-content .questionnaire-form .panel-body{ padding: 10px; }
section.po-wapper .po-content .questionnaire-form .panel-body .form-control{ font-size: 14px; font-weight: 500; }

/* Thank You */
section.po-wapper .po-content .thankyou{  text-align: center; }
section.po-wapper .po-content .thankyou .profile{ border-radius: 50%; }
section.po-wapper .po-content .thankyou .profile img{ width: 66px; height: 66px; border-radius: 50%; object-fit: cover; }
section.po-wapper .po-content .thankyou h2{ margin: 0 0 10px; color: var(--body_text_color); display: inline-block; border-bottom: 2px solid var(--body_background_color); }



/* Schedule Appointment */
section.po-wapper .po-content .schedule-appointment {background: var(--header_footer_bg); color: var(--body_text_color); max-width: 400px;  border-radius: 5px; margin: 0 auto; padding: 20px;}
section.po-wapper .po-content .schedule-appointment .text{ text-align: center; }
section.po-wapper .po-content .schedule-appointment .or-line { text-align: center; position: relative; margin: 20px 0;}
section.po-wapper .po-content .schedule-appointment .or-line span{ background: var(--header_footer_bg); padding: 0 10px; position: relative; z-index: 1; }
section.po-wapper .po-content .schedule-appointment .or-line:after{ content: ""; position: absolute; border-bottom: 2px solid #D2D2D2; left: 0; right: 0; top: 10px; }
section.po-wapper .po-content .schedule-appointment .form-group { text-align: left; }

/* Footer */
footer.po-footer { padding: 6px 15px; text-align: center; background: var(--header_footer_bg); }
footer.po-footer button{ text-decoration: underline; color: var(--body_text_color);  }

/* Center Small Modal */
.center-modal { padding: 0 10px; position: fixed;  top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5); display: flex; align-items: center; z-index: 99;}
.center-modal.open .inner-modal{ opacity: 1;  transform: scale(1); -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); }
.center-modal.open .inner-modal .bottom-btn button{ margin: 0 5px; }
.center-modal .inner-modal { opacity: 0; position: relative; border-radius: 5px;  background: var(--header_footer_bg); width: 100%; max-width: 410px; margin: 0 auto; transform: scale(0);}
.center-modal .inner-modal .modal-head h4{ font-size: 16px; margin: 20px 0 0; padding: 10px 15px; text-align: center; }
.center-modal .inner-modal .modal-head .close-modal{ position: absolute; top: 14px; right: 10px; }
.center-modal .inner-modal .modal-body{ padding: 15px; }
.center-modal .inner-modal .modal-body textarea{ resize: none; }
.center-modal .inner-modal .modal-footer{ padding: 10px 15px; }

/*open in app*/
.open-in-app{display:flex;align-items:center;justify-content:center}
.open-in-app .img-btn{line-height:0}

.privacy-href{color: var(--primary_button_fill_color);}